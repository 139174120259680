import { useCallback, useMemo, useRef, useState } from 'react';
import type { ColDef, GridApi, GridReadyEvent } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import { ServerSideRowModelModule } from '@ag-grid-enterprise/server-side-row-model';
import { useIntl } from 'react-intl';

import { getCellValueFormatter } from '@/core/format.ts';
import { getMeasureType, type MeasureType } from '@/core/measures.ts';
import type { MdxColumn, RowData } from '@/core/parsing/parseResponse.ts';
import type { WebSocketConnection } from '@/core/webSocket/types.ts';
import { useAppSelector } from '@/store/hooks.ts';
import {
  selectWidgetQueryParams,
  type WidgetConfigParams,
} from '@/store/slices/widget/widgetsSlice.ts';
import {
  isWidgetDefMonoAxis,
  type WidgetColumn,
  type WidgetColumnMultiAxis,
  type WidgetDef,
  type WidgetHierarchyColumn,
  type WidgetQueryParams,
} from '@/store/slices/widget/widgetTypes.ts';
import { store } from '@/store/store.ts';
import { storeEventEmitter } from '@/store/storeEventEmitter.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { Dropdown } from '@/components/common/bootstrap/Dropdown.tsx';
import { MessageTooltip } from '@/components/common/bootstrap/MessageTooltip.tsx';
import { useEventEmitter } from '@/components/common/hooks/useEventEmitter.ts';
import { useMeasureFormat } from '@/components/common/hooks/useMeasureFormat.tsx';
import { getRowId } from '@/components/equityRisk/dataSource/WebSocketDataSource.ts';
import { getCellClassRulesForWidgets } from '@/components/equityRisk/gridMappings/style.ts';
import {
  getMeasureWidgetName,
  type WidgetParams,
} from '@/components/equityRisk/widgets/allWidgets.ts';
import { WidgetBadges } from '@/components/equityRisk/widgets/WidgetBadges.tsx';
import { WidgetDataSource } from '@/components/equityRisk/widgets/WidgetDataSource.ts';
import type { WidgetDropdownChoice } from '@/components/equityRisk/widgets/WidgetPanel.tsx';
import type { CubeMode } from '@/types/AppConfig.ts';
import { objectKeys } from '@/utils/libs/entries.ts';

interface WidgetProps {
  webSocketConnection: WebSocketConnection;
  widgetDef: WidgetDef<WidgetParams>;
  onMenuClick: (widgetId: string, dropdownChoice: WidgetDropdownChoice) => void;
}

export function Widget(props: WidgetProps) {
  const widgetQueryParams = useAppSelector(selectWidgetQueryParams);

  const [gridApi, setGridApi] = useState<GridApi<RowData> | undefined>();
  const { webSocketConnection, onMenuClick, widgetDef } = props;
  const { formatMessage } = useIntl();
  const refDataSource = useRef<WidgetDataSource>();
  const { params, name, id } = widgetDef;
  const [colDefs, { updateColumns }] = useWidgetColDefs(widgetDef, widgetQueryParams.cubeMode);

  const configParamsUpdateListener = useCallback<
    (params: Record<string, WidgetConfigParams>) => void
  >(
    paramsById => {
      const updatedParams = paramsById[widgetDef.id];
      if (updatedParams === undefined) {
        return;
      }
      refDataSource.current?.refreshQuery(widgetDef.getQuery(widgetQueryParams, updatedParams));
    },
    [widgetDef, widgetQueryParams],
  );

  const queryParamsUpdateListener = useCallback<(params: WidgetQueryParams) => void>(
    updatedWidgetQueryParams => {
      refDataSource.current?.refreshQuery(
        widgetDef.getQuery(updatedWidgetQueryParams, widgetDef.params),
      );
    },
    [widgetDef],
  );

  useEventEmitter(storeEventEmitter, 'onWidgetConfigParamsUpdate', configParamsUpdateListener);
  useEventEmitter(storeEventEmitter, 'onWidgetQueryParamsUpdate', queryParamsUpdateListener);

  const onGridReady = ({ api }: GridReadyEvent<RowData>) => {
    const onDynamicColumnsReady = widgetDef.category === 'MultiAxis' ? updateColumns : undefined;

    const dataSource = new WidgetDataSource(
      webSocketConnection,
      api,
      widgetDef.getQuery(widgetQueryParams, widgetDef.params),
      id,
      store,
      onDynamicColumnsReady,
    );

    api.updateGridOptions({ serverSideDatasource: dataSource });
    refDataSource.current = dataSource;
    setGridApi(api);
  };

  const onItemClick = (choice: WidgetDropdownChoice) => onMenuClick(id, choice);

  const menuPanelItems: WidgetDropdownChoice[] =
    objectKeys(params).length > 0 ? ['Edit', 'Remove'] : ['Remove'];

  return (
    <div className="h-100 w-100 d-flex flex-column   p-2 gap-1">
      <div className="widget-draghandle cursor-grab">
        <div className="d-flex justify-content-between align-items-center">
          <h5>{name}</h5>
          <div className="d-flex ">
            <MessageTooltip delay={300} placement="bottom" messageId={'Widget.Buttons.Autosize'}>
              <Button flat className="btn-icon" onClick={() => gridApi?.autoSizeAllColumns()}>
                <i className="icon ">compare_arrows</i>
              </Button>
            </MessageTooltip>

            <Dropdown<WidgetDropdownChoice>
              className="btn-icon"
              flat={true}
              variant="primary"
              itemsAsObjects={menuPanelItems}
              itemToString={item => formatMessage({ id: `WidgetPanel.Dropdown.${item}` })}
              onItemClick={onItemClick}
            >
              <i className="icon">more_vert</i>
            </Dropdown>
          </div>
        </div>
      </div>

      <WidgetBadges params={params} />
      <div className="flex-grow h-100 ag-theme-alpine ag-theme-era">
        <AgGridReact<RowData>
          modules={[ServerSideRowModelModule]}
          getRowId={getRowId}
          onGridReady={onGridReady}
          columnDefs={colDefs}
          rowModelType="serverSide"
          rowHeight={25}
          serverSideEnableClientSideSort={true}
          suppressMenuHide={false}
          loading={colDefs.length === 0}
        />
      </div>
    </div>
  );
}

type UseWidgetColDefs = [ColDef[], { updateColumns: (columns: MdxColumn[]) => void }];

function useWidgetColDefs(
  widgetDef: WidgetDef<WidgetParams>,
  cubeMode: CubeMode,
): UseWidgetColDefs {
  const [mdxColumns, setMdxColumns] = useState<MdxColumn[]>([]);

  const negativeNumberFormat = useAppSelector(state => state.userPreferences.negativeNumberFormat);
  const numberFormat = useAppSelector(state => state.userPreferences.numberFormat);
  const measureFormat = useMeasureFormat();

  const colDefs = useMemo(() => {
    const widgetColumns = isWidgetDefMonoAxis(widgetDef)
      ? widgetDef.columns
      : getDynamicWidgetColumns(mdxColumns, widgetDef.params);

    return widgetColumns?.map((widgetColumn: WidgetColumn) => {
      const colId =
        widgetColumn.type === 'measure'
          ? getMeasureWidgetName(widgetColumn.measureId, cubeMode)
          : widgetColumn.name;

      const measureType = getWidgetMeasureType(widgetColumn, widgetDef.params);

      const headerName =
        widgetColumn.type === 'measureCrossHierarchy'
          ? getMeasureCrossHierarchyName(widgetColumn.name)
          : measureFormat(widgetColumn.type === 'hierarchy' ? 'Hierarchy' : widgetColumn.measureId);

      const colDef: ColDef = {
        colId,
        headerName,
        valueGetter: params => params.data?.[colId],
        valueFormatter: getCellValueFormatter(numberFormat, negativeNumberFormat, measureType),
        cellClass: measureType === 'quantity' ? 'fst-italic' : undefined,
        cellClassRules: getCellClassRulesForWidgets(widgetColumn),
        type:
          widgetColumn.type === 'measure' || widgetColumn.type === 'measureCrossHierarchy'
            ? 'rightAligned'
            : undefined,
      } satisfies ColDef;

      return colDef;
    });
  }, [widgetDef, mdxColumns, cubeMode, measureFormat, numberFormat, negativeNumberFormat]);

  return [colDefs, { updateColumns: setMdxColumns }];
}

function getWidgetMeasureType(column: WidgetColumn, widgetParams: WidgetParams): MeasureType {
  if (column.type === 'measureCrossHierarchy') {
    if (widgetParams.metric === undefined) {
      return 'text';
    }
    return getMeasureType(widgetParams.metric.value);
  } else if (column.type === 'measure') {
    return getMeasureType(column.measureId);
  } else {
    return 'text';
  }
}

function getMeasureCrossHierarchyName(name: string): string {
  return name.split('/')[1];
}

function getDynamicWidgetColumns(
  mdxColumns: MdxColumn[],
  widgetParams: WidgetParams,
): WidgetColumnMultiAxis[] {
  const { metric, rowAggregation, columnAggregation } = widgetParams;
  if (metric === undefined || rowAggregation === undefined || columnAggregation === undefined) {
    return [];
  }

  return mdxColumns.map(({ path, type }) => {
    const name = path.join('/');
    if (type === 'hierarchy') {
      return { name, type: 'hierarchy' } as WidgetHierarchyColumn;
    }
    return {
      type: 'measureCrossHierarchy',
      name,
      measureId: metric.value,
      rowHierarchy: rowAggregation.value,
      columnHierarchy: columnAggregation.value,
    };
  });
}
