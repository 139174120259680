import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useRouter } from '@tanstack/react-router';
import { FormattedMessage } from 'react-intl';

import {
  FIXED_CACHE_ORDERGROUP,
  usePostOrderGroupsMutation,
} from '@/store/api/hedgerApi/hedgerApi.ts';
import { Button } from '@/components/common/bootstrap/Button.tsx';
import { Loader } from '@/components/common/bootstrap/Loader.tsx';
import { useHedgerSettingModal } from '@/components/hedger/execution/settings/useHedgerSettingModal.ts';

export function HedgerExecutionPage() {
  const { trackEvent } = useMatomo();
  const [_, { isLoading }] = usePostOrderGroupsMutation({
    fixedCacheKey: FIXED_CACHE_ORDERGROUP,
  });
  const { navigate } = useRouter();

  function onManageScenariosClick() {
    trackEvent({ category: 'Hedger', action: 'Navigate to scenarios' });
    return navigate({ to: '/hedger/scenarios' });
  }

  const { show } = useHedgerSettingModal();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="d-flex flex-column flex-grow-1 pt-2">
      <div className="d-flex justify-content-between align-items-center px-4 pb-2">
        <div className="display-4">Hedger</div>
        <div className="d-flex gap-2">
          <Button onClick={onManageScenariosClick}>
            <FormattedMessage id="Hedger.Scenarios.Title" />
          </Button>
        </div>
      </div>

      <div className="d-flex text-center text-secondary flex-grow-1">
        <div className="d-flex align-items-center justify-content-center flex-grow-1">
          <div className="w-25">
            <em className="icon icon-xl">search</em>

            <h4>No scenario selected</h4>
            <p>To start to select a scenario for the hedging use the button below.</p>
            <Button discreet size="lg" variant="secondary" onClick={() => show()}>
              <FormattedMessage id="Hedger.Execution" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
